import type { ConcreteIndicator } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import { ReactiveInjectable, reacts, inject, injectable } from 'src/features/ioc';
import { getWholeSlugMap } from 'src/lib/dictionary/dictionary';
import { getFullArticleContent } from 'src/lib/newQuant/index';
import type { NewsWidgetModel } from 'src/models/NewsWidgetModel';
import { type Logger } from 'src/services/Logger';
import { createAlphaLensFromSymbol, createChartFromSymbol } from 'src/store/actions/container';
import { userSetNewsStrategy, userSetNewsSymbolFilter, userSetNewsUniverse } from 'src/store/actions/widgets/news';
import { userDoubleClickedSymbolFromTable } from 'src/store/actions/widgets/results';
import type { NewsWidgetViewModel } from 'src/store/types';
import type { ReactBindings } from 'src/types/bindings';

interface NewsWidgetModelState {
    // currentSymbolsTracked: EventProxySet<string>;
    changeCloseSlug: ConcreteIndicator | null;
    symbolSlug: ConcreteIndicator | null;
}

@injectable()
export class NewsWidgetModelImpl extends ReactiveInjectable implements NewsWidgetModel {
    #state: NewsWidgetModelState;
    #logger: Logger;
    // #symbolToUpDownTracker = new Map<string, EventTarget>();
    // #symbolToLastUpDownNeither = new Map<string, 'up' | 'down' | 'neither'>();
    // #articlesDiscovered: ArticleViewModel[] = [];
    // // #currentArticleIdsTracked = new Set<string>();
    // #symbolsDiscoveredBetweenPollingRounds = new Set<string>();
    // #symbolsOriginallyEnteredStrategyTime = new Map<string, string>();
    // #currentFormattedDay = ZonedDateTime.ofInstant(Instant.now(), ZoneId.SYSTEM).format(dateFormatter);

    constructor(
        @inject('WidgetDataModel') @reacts private widgetData: ReactBindings['WidgetDataModel'],
        @inject('Store') @reacts private store: ReactBindings['Store'],
        @inject('QueryClient') private readonly queryClient: ReactBindings['QueryClient'],
        @inject('Logger') logger: Logger,
    ) {
        // eslint-disable-next-line prefer-rest-params
        super(...arguments);

        this.#state = {
            // currentSymbolsTracked: new EventProxySet(new Set()),
            changeCloseSlug: null,
            symbolSlug: null,
        };

        this.#logger = logger.getSubLogger({ name: 'NewsWidgetModel' });
    }

    async init(tabId: string) {
        this.widgetData.init(tabId);

        const allSlugs = await this.queryClient.fetchQuery({
            queryKey: ['whole-slug-map'],
            queryFn: () => getWholeSlugMap(),
        });

        this.state = {
            // currentSymbolsTracked: new EventProxySet(new Set()),
            symbolSlug: allSlugs['symbol'],
            changeCloseSlug: allSlugs['symbol::changeClose'],
        };

        // this.#logger.debug({ message: 'got slugmap', allSlugs });
    }

    get widget() {
        return this.widgetData.widget as NewsWidgetViewModel;
    }

    get strategyId() {
        return this.widget.strategies[0];
    }

    onChangeSymbolFilter(symbolFilter: string): void {
        this.store.dispatch(userSetNewsSymbolFilter(this.widgetData.tabId, symbolFilter));
    }

    private resetState() {
        // this.#articlesDiscovered = [];
        // this.#currentArticleIdsTracked = new Set();
        // this.#symbolsOriginallyEnteredStrategyTime = new Map();
        // this.#symbolsDiscoveredBetweenPollingRounds.clear();
        // this.state.currentSymbolsTracked.clear();

        this.state = {
            ...this.state,
            // The following does not change
            changeCloseSlug: this.#state.changeCloseSlug,
            symbolSlug: this.#state.symbolSlug,
        };
        this.store.dispatch(userSetNewsSymbolFilter(this.widgetData.tabId, null));
    }

    set strategyId(strategyId: string | null) {
        this.resetState();
        this.store.dispatch(userSetNewsStrategy(this.widgetData.tabId, strategyId));
    }

    get universeId() {
        return this.widget.universe;
    }

    set universeId(universeId: string | null) {
        this.resetState();
        this.store.dispatch(userSetNewsUniverse(this.widgetData.tabId, universeId));
    }

    // private mergeArticlesIntoList(articles: NewsStory[]) {
    //     const newViewModels = articles.flatMap((x) =>
    //         x.symbols
    //             .filter((symbol) => {
    //                 // We can get more symbols than we have ever seen in a strategy, so we throw those out
    //                 if (!this.#symbolsOriginallyEnteredStrategyTime.has(symbol)) return false;

    //                 // You can get the same article in a single article dump in the following scenario:
    //                 // Article matches symbols A B C D with the same benzinga article ID
    //                 // Each article of A ref's B C D as well
    //                 // Each article of B ref's A C D as well
    //                 const viewModelId = `${symbol}__${x.id}`;
    //                 if (this.#currentArticleIdsTracked.has(viewModelId)) return false;
    //                 this.#currentArticleIdsTracked.add(viewModelId);
    //                 return true;
    //             })
    //             .map<ArticleViewModel>((symbol) => {
    //                 const offsetDate = ZonedDateTime.ofInstant(x.publishedAt, ZoneId.SYSTEM);

    //                 return {
    //                     ...x,
    //                     viewModelId: `${symbol}__${x.id}`,
    //                     symbol,
    //                     symbols: [symbol].concat(x.symbols.filter((x) => x !== symbol)),
    //                     publishedTime: offsetDate.format(timeFormatter),
    //                     localDate: offsetDate.format(dateFormatter),
    //                 };
    //             }),
    //     );

    //     const allArticles = [...newViewModels, ...this.#articlesDiscovered];

    //     allArticles.sort((a, b) => b.publishedAt.compareTo(a.publishedAt));

    //     this.#articlesDiscovered = allArticles;
    //     this.#currentArticleIdsTracked = this.#currentArticleIdsTracked.union(
    //         new Set(newViewModels.map((x) => x.viewModelId)),
    //     );
    // }

    // updateSymbols(diffList: { symbolRequiresTracking: false; symbol: string; currentChangeValue: number }[]) {
    //     // this.#logger.debug({ message: 'received updates for symbols ', details: { diffList } });

    //     // The flow of a symbol is fairly simple (for now)
    //     // On initial symbol discovery, we request the last 7 days of articles for that symbol
    //     // If the symbol has been previously discovered, we don't re-request it, but we do set whether or
    //     // not it is currently matching

    //     // We are querying the entire universe at a time, but the pagination process of the table client
    //     // makes this necessary to act as a diff each time so that we do not have to
    //     // pray that bufferDebounce and RXJS actually work

    //     const now = Instant.now();

    //     diffList.forEach((x) => {
    //         const { symbol, symbolRequiresTracking, currentChangeValue } = x;

    //         if (symbolRequiresTracking) {
    //             this.#symbolsDiscoveredBetweenPollingRounds.add(symbol);

    //             if (!this.#symbolsOriginallyEnteredStrategyTime.has(symbol)) {
    //                 this.#symbolsOriginallyEnteredStrategyTime.set(
    //                     symbol,
    //                     ZonedDateTime.ofInstant(now, ZoneId.SYSTEM).format(timeFormatter),
    //                 );
    //             }

    //             if (!this.#state.currentSymbolsTracked.has(symbol)) {
    //                 this.#state.currentSymbolsTracked.add(symbol);
    //             }
    //         } else {
    //             if (this.#state.currentSymbolsTracked.has(symbol)) {
    //                 this.#state.currentSymbolsTracked.delete(x.symbol);
    //             }
    //         }

    //         const currentValue = this.#symbolToLastUpDownNeither.get(symbol);

    //         const newValue = currentChangeValue < 0 ? 'up' : currentChangeValue > 0 ? 'down' : 'neither';
    //         if (newValue !== currentValue) {
    //             this.#symbolToLastUpDownNeither.set(symbol, newValue);
    //             this.#symbolToUpDownTracker.get(symbol)?.dispatchEvent(
    //                 new CustomEvent('symbolChangeUpdated', {
    //                     detail: newValue,
    //                 }),
    //             );
    //         }
    //     });

    //     // Force re-render after bulk update of symbolToLastUpDownNeither and currentSymbolsTracked
    //     this.rerender();
    // }

    @reacts private set state(newState: NewsWidgetModelState) {
        this.#state = newState;
    }

    // getSymbolChangeTracker(symbol: string) {
    //     if (!this.#symbolToUpDownTracker.has(symbol)) {
    //         this.#symbolToUpDownTracker.set(symbol, new EventTarget());
    //     }

    //     return this.#symbolToUpDownTracker.get(symbol)!;
    // }

    // originalSignalEntryTime(symbol: string) {
    //     const val = this.#symbolsOriginallyEnteredStrategyTime.get(symbol);
    //     return val || '';
    // }

    // symbolIsUpDownOrNeither(symbol: string) {
    //     return this.#symbolToLastUpDownNeither.get(symbol) ?? 'neither';
    // }

    // symbolIsCurrentlyTracked(symbol: string) {
    //     return this.#state.currentSymbolsTracked.has(symbol);
    // }

    onSelectSymbol(symbol: string) {
        this.store.dispatch(userDoubleClickedSymbolFromTable(this.widgetData.tabId, symbol));
    }

    openChartForSymbol(symbol: string) {
        this.store.dispatch(
            createChartFromSymbol({
                defaultSymbol: symbol,
                channelId: this.widget.channelId,
            }),
        );
    }

    openLensForSymbol(symbol: string) {
        this.store.dispatch(
            createAlphaLensFromSymbol({
                defaultSymbol: symbol,
                channelId: this.widget.channelId,
            }),
        );
    }

    // get currentSymbolsBeingTracked() {
    //     return this.#state.currentSymbolsTracked;
    // }

    get changeCloseSlug() {
        return this.#state.changeCloseSlug;
    }

    get symbolSlug() {
        return this.#state.symbolSlug;
    }

    get symbolFilter() {
        return this.widget.symbolFilter ?? '';
    }

    set symbolFilter(value: string) {
        this.store.dispatch(userSetNewsSymbolFilter(this.widgetData.tabId, value === '' ? null : value));
    }

    // shouldShowLocalDateRow(date: string) {
    //     return date !== this.#currentFormattedDay;
    // }

    fetchArticle(articleId: string) {
        return getFullArticleContent(articleId);
    }

    // // ToDo: On UI, denote this if false
    // get symbolBeingFilteredIsCurrentlyTracked() {
    //     return this.#state.currentSymbolsTracked.has(this.symbolFilter);
    // }

    // getMergedArticleFeed() {
    //     const currentSymbolFilter = this.symbolFilter;
    //     const symbolsTracked = this.#state.currentSymbolsTracked;
    //     const discoveredBetweenRounds = this.#symbolsDiscoveredBetweenPollingRounds;

    //     const allSymbolsCurrentlyValidForQuery = symbolsTracked.union(discoveredBetweenRounds).values().toArray();

    //     const symbolsToQuery =
    //         currentSymbolFilter &&
    //         allSymbolsCurrentlyValidForQuery.find((x) => x.toLowerCase() === currentSymbolFilter.toLowerCase())
    //             ? [currentSymbolFilter]
    //             : allSymbolsCurrentlyValidForQuery;

    //     this.#symbolsDiscoveredBetweenPollingRounds.clear();

    //     if (symbolsToQuery.length === 0) {
    //         this.#logger.debug({
    //             message: 'no news to query',
    //         });

    //         return Promise.resolve(this.#articlesDiscovered);
    //     }

    //     const queryStartTime = Instant.now();

    //     this.#logger.trace({
    //         message: 'polling for news',
    //         time: queryStartTime,
    //         symbolCount: symbolsToQuery.length,
    //         filter: currentSymbolFilter,
    //     });

    //     // ToDo: Now to 3 TRADING days ago; 5 calendar days temporarily
    //     const query: NewsQuery = {
    //         symbols: symbolsToQuery,
    //         maxResults: 300,
    //         end: queryStartTime,
    //         start: queryStartTime.minus(5, ChronoUnit.DAYS),
    //     };

    //     return getBulkNewsBySymbol(query)
    //         .then((articles) => {
    //             const queryCompletedTime = Instant.now();
    //             const queryTimeMs = Duration.between(queryStartTime, queryCompletedTime).toMillis();

    //             if (articles.length === 0) {
    //                 this.#logger.debug({
    //                     message: 'Received no news for symbols',
    //                     time: queryCompletedTime,
    //                     queryTimeMs,
    //                     symbols: symbolsToQuery,
    //                     filter: currentSymbolFilter,
    //                 });
    //             } else {
    //                 this.#logger.trace({
    //                     message: 'Received news for symbols',
    //                     time: Instant.now(),
    //                     symbolCount: symbolsToQuery.length,
    //                     resultCount: articles.length,
    //                     queryTimeMs,
    //                     filter: currentSymbolFilter,
    //                 });
    //             }

    //             this.mergeArticlesIntoList(articles);
    //             return this.#articlesDiscovered;
    //         })
    //         .catch((err) => {
    //             this.#logger.error({ message: 'error polling for news', err, symbols: symbolsToQuery });

    //             return this.#articlesDiscovered;
    //         });
    // }
}
