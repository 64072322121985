import type { UseQueryOptions } from '@tanstack/react-query';
import type { CallInfo } from '@thinkalpha/language-services';
import { type Sample, type ConcreteIndicator } from '@thinkalpha/platform-ws-client/contracts/dictionary.js';
import qs from 'qs';
import type { Observable } from 'rxjs';
import { of, pipe } from 'rxjs';
import { bufferTime, catchError, filter, map, mergeMap } from 'rxjs/operators';
import { container } from 'src/StaticContainer';
import { rxapi, api } from 'src/api';
import type {
    AllIndicatorVersionsViewModel,
    ConcreteIndicatorViewModel,
    ImportsStatusesViewModel,
    IndicatorAndReferencesViewModel,
    IndicatorFilterViewModel,
    IndicatorImportViewModel,
    IndicatorImportRefViewModel,
    IndicatorPatchViewModel,
    IndicatorRefViewModel,
    NewIndicatorViewModel,
    TagViewModel,
    TagFilterViewModel,
    TagTreeLevelViewModel,
    IndicatorWithVersionsViewModel,
} from 'src/contracts/dictionary-view-model';
import type { ResourceQuery, ResourceQueryResponseWithMeta } from 'src/contracts/resource-query';
import { processQuery } from 'src/lib/paging';

const log = container.get('Logger').getSubLogger({ name: 'dictionary-tag-tree' });

export function getTagTreeLevel(
    parentsAndMe: string[],
    includeIndicators: boolean,
    filters: IndicatorFilterViewModel = {},
): Observable<TagTreeLevelViewModel> {
    return rxapi
        .get<TagTreeLevelViewModel>('/dictionary/tags/tree', {
            params: { parentsAndMe, includeIndicators, ...filters },
        })
        .pipe(map((x) => x.data));
}

export function getTagTreeLevelP(
    parentsAndMe: string[],
    includeIndicators: boolean,
    filters: IndicatorFilterViewModel = {},
): Promise<TagTreeLevelViewModel> {
    return api
        .get<TagTreeLevelViewModel>('/dictionary/tags/tree', {
            params: { parentsAndMe, includeIndicators, ...filters },
        })
        .then((x) => x.data);
}

export async function getIndicators(filters: IndicatorFilterViewModel = {}): Promise<ConcreteIndicatorViewModel[]> {
    const response = await api.get<ConcreteIndicatorViewModel[]>('/dictionary/indicators', {
        params: { ...filters },
    });

    if (!response.data) {
        log.error('No data returned from getIndicators', {
            filters,
            response,
        });

        return [];
    }

    return response.data;
}

export function getIndicators$(filters: IndicatorFilterViewModel = {}): Observable<ConcreteIndicatorViewModel[]> {
    return rxapi
        .get<ConcreteIndicatorViewModel[]>('/dictionary/indicators', { params: { ...filters } })
        .pipe(map((x) => x.data));
}

export const getIndicatorsQuery = async (
    query: ResourceQuery,
): Promise<ResourceQueryResponseWithMeta<ConcreteIndicatorViewModel>> =>
    (await api.get(`/dictionary/query${processQuery(query)}&expand_users=true`)).data;

export async function getIndicatorById(
    id: string,
    expandImports: true,
    includeDiscarded?: boolean,
): Promise<IndicatorAndReferencesViewModel<IndicatorImportViewModel>>;
export async function getIndicatorById(id: string): Promise<IndicatorAndReferencesViewModel>;
export async function getIndicatorById(
    id: string,
    expandImports?: true,
    includeDiscarded?: boolean,
): Promise<IndicatorAndReferencesViewModel> {
    const indicators = await api.get<IndicatorAndReferencesViewModel>(
        `/dictionary/indicators/${id}${expandImports ? '?$expand=imports' : ''}${
            includeDiscarded ? '&includeDiscarded=true' : ''
        }`,
    );
    return indicators.data;
}

export async function getIndicatorsByIds(ids: string[], withVersions = false): Promise<ConcreteIndicatorViewModel[]> {
    const indicators = await api.get<ConcreteIndicatorViewModel[]>(`/dictionary/bulk`, {
        params: { ids, withVersions: withVersions || undefined },
        paramsSerializer: {
            // TODO: Axios has better support for this now than a custom serializer as of 1.0
            serialize: (params) => {
                return qs.stringify(params);
            },
        },
    });
    return indicators.data;
}

// TODO(@jsonnull): Make into `getIndicatorsFromIndicatorRefs` and take into account versions
export async function getIndicatorsByIdsWithVersions(ids: string[]): Promise<ConcreteIndicatorViewModel[]> {
    if (!ids.length) return [];

    const indicators = await api.get<ConcreteIndicatorViewModel[]>(`/dictionary/bulk`, {
        params: { ids, withVersions: true },
        paramsSerializer: {
            // TODO: Axios has better support for this now than a custom serializer as of 1.0
            serialize: (params) => {
                return qs.stringify(params);
            },
        },
    });
    return indicators.data;
}

export async function getIndicatorsByIdsWithVersion(ids: string[]): Promise<IndicatorWithVersionsViewModel[]> {
    const result = await getIndicatorsByIds(ids, true);

    return result as IndicatorWithVersionsViewModel[];
}

export async function getSuggestedIndicators(): Promise<ConcreteIndicatorViewModel[]> {
    return (await api.get<ConcreteIndicatorViewModel[]>('/dictionary/suggested')).data;
}

export function createIndicator(
    indicator: NewIndicatorViewModel,
    expandImports: true,
): Observable<ConcreteIndicatorViewModel<IndicatorImportViewModel>>;
export function createIndicator(indicator: NewIndicatorViewModel): Observable<ConcreteIndicatorViewModel>;
export function createIndicator(
    indicator: NewIndicatorViewModel,
    expandImports?: true,
): Observable<ConcreteIndicatorViewModel> {
    return rxapi
        .post<ConcreteIndicatorViewModel>(`/dictionary/indicators${expandImports ? '?$expand=imports' : ''}`, indicator)
        .pipe(map((x) => x.data));
}

export function updateIndicator(
    patch: IndicatorPatchViewModel,
    id: string,
    expandImports: true,
): Observable<ConcreteIndicatorViewModel<IndicatorImportViewModel>>;
export function updateIndicator(patch: IndicatorPatchViewModel, id: string): Observable<ConcreteIndicatorViewModel>;
export function updateIndicator(
    patch: IndicatorPatchViewModel,
    id: string,
    expandImports?: true,
): Observable<ConcreteIndicatorViewModel> {
    return rxapi
        .patch<ConcreteIndicatorViewModel>(
            `/dictionary/indicators/${id}${expandImports ? '?$expand=imports' : ''}`,
            patch,
        )
        .pipe(map((x) => x.data));
}

export async function getAllIndicatorVersions(ids: string[]): Promise<AllIndicatorVersionsViewModel> {
    if (!ids.length) return {};
    const instances = await api.get<AllIndicatorVersionsViewModel>(`/dictionary/indicators/versions/all`, {
        params: { ids },
    });
    return instances.data;
}

export async function getIndicatorVersion(id: string, version: number): Promise<ConcreteIndicatorViewModel> {
    const indicators = await api.get<ConcreteIndicatorViewModel>(`/dictionary/indicators/${id}/${version}`);
    return indicators.data;
}

export async function getImportsFromRefs(
    refs: IndicatorImportRefViewModel[],
    /**
     * Force the latest version of the indicator to be used, rather than the version specified in the ref.
     */
    forceLatest = false,
): Promise<IndicatorImportViewModel[]> {
    if (!refs.length) return [];

    const indicators = await getIndicatorsByIdsWithVersion(refs.map((x) => x.id));

    const indicatorImports: (IndicatorImportViewModel | null)[] = refs.map((ref) => {
        const indicator = indicators.find((x) => x.id === ref.id)!;

        if (!indicator) {
            // console.error(`Indicator with id ${ref.id} not found`);
            return null;
        }

        if (forceLatest) {
            return {
                ...ref,
                ...indicator,
                alias: ref.alias,
            } satisfies IndicatorImportViewModel;
        }

        const matchingVersion =
            indicator.version === ref.version
                ? indicator
                : indicator.previousVersions.find((x) => x.version === ref.version)!;

        if (!matchingVersion) {
            // console.error(`Indicator with id ${ref.id} and version ${matchingVersion} found`);
            return null;
        }

        // We may double spread here, just because the previous version won't have certain things, I suppose.
        return {
            ...ref,
            ...indicator,
            ...matchingVersion,
            alias: ref.alias,
        } satisfies IndicatorImportViewModel;
    });

    return indicatorImports.filter(Boolean) as IndicatorImportViewModel[];
}

export function getImportsStatuses(records: IndicatorRefViewModel[]): Observable<ImportsStatusesViewModel> {
    return rxapi.post<ImportsStatusesViewModel>(`/dictionary/indicators/status`, records).pipe(map((x) => x.data));
}

export function getIndicatorsFromSymbols(symbols: string[], key: string): Promise<ConcreteIndicatorViewModel[]> {
    return api
        .get<
            ConcreteIndicatorViewModel[]
        >(`/dictionary/indicators/by-symbol?${symbols.map((sym) => `symbols=${sym}`).join('&')}&${key ? `key=${key}` : ''}`)
        .then((x) => x.data);
}

/**
 * @deprecated - Use getIndicatorsFromSymbols
 */
export function getIndicatorsFromSymbols$(symbols: string[], key: string): Observable<ConcreteIndicatorViewModel[]> {
    return rxapi
        .get<
            ConcreteIndicatorViewModel[]
        >(`/dictionary/indicators/by-symbol?${symbols.map((sym) => `symbols=${sym}`).join('&')}&${key ? `key=${key}` : ''}`)
        .pipe(map((x) => x.data));
}

// todo: type this return
/**
 * @deprecated - No rxjs
 */
export function getIndicatorsFromCallNodes(key: string) {
    return pipe(
        bufferTime(100),
        filter((x) => !!x.length),
        mergeMap((nodes: CallInfo[]) =>
            getIndicatorsFromSymbols$(
                nodes.map((node) => node.source),
                key,
            ).pipe(catchError(() => of([]))),
        ),
    );
}

export async function deleteIndicator(id: string): Promise<void> {
    await api.delete<ConcreteIndicatorViewModel>(`/dictionary/indicators/${id}`);
}

export async function getIndicatorTags(filters: TagFilterViewModel = {}): Promise<TagViewModel[]> {
    const indicators = await api.get<TagViewModel[]>('/dictionary/tags', { params: filters });
    return indicators.data;
}

export function getIndicatorTags$(filters: TagFilterViewModel = {}): Observable<TagViewModel[]> {
    const indicators$ = rxapi.get<TagViewModel[]>('/dictionary/tags', { params: filters }).pipe(map((x) => x.data));
    return indicators$;
}

export function getIndicatorsBySlug(slugs: string[]): Promise<ConcreteIndicator[]> {
    return api.get(`/dictionary/indicators/by-slugs`, { params: { slug: slugs } }).then((res) => res.data);
}

type ImportantSlugs =
    | 'PositionKey'
    | 'symbol'
    | 'symbol::changeClose'
    | 'symbol::last'
    | 'symbol::volume'
    | 'ExecutionKey'
    | 'account::fictitiousName'
    | 'AccountKey'
    | 'account::trader'
    | 'position::openShares'
    | 'symbol::bid'
    | 'execution::symbol'
    | 'position::symbol'
    | 'execution::AccountKey'
    | 'OrderKey'
    | 'symbol::ask'
    | 'account::isAdmin'
    | 'order::AccountKey'
    | 'position::AccountKey'
    | 'account::displayName'
    | 'account::isWriter'
    | 'order::symbol'
    | 'account::exists'
    | 'order::side'
    | 'order::clOrdId'
    | 'order::account'
    | 'position::marketValue'
    | 'account::accountId'
    | 'account::name'
    | 'account::isOwner'
    | 'position::account'
    | 'entryTime'
    | 'exitTime'
    | 'position::openOrders';

export function getWholeSlugMap(): Promise<Record<ImportantSlugs, ConcreteIndicator>> {
    return api.get(`/dictionary/indicators/slugmap`).then((res) => res.data);
}

export function constructIndicatorsBySlugMapQuery<T extends string>(
    ...slugs: T[]
): UseQueryOptions<Record<T, ConcreteIndicator>> {
    return {
        queryKey: ['indicators-by-slug', ...slugs],
        queryFn: () =>
            getIndicatorsBySlug(slugs).then((inds) =>
                inds.reduce(
                    (acc, ind) => {
                        acc[ind.matchSlug as T] = ind;
                        return acc;
                    },
                    {} as Record<T, ConcreteIndicator>,
                ),
            ),
    };
}

export async function getIndicatorSamples(id: string): Promise<Sample[]> {
    const indicators = await api.get<Sample[]>(`/dictionary/indicators/${id}/sample`);
    return indicators.data;
}
