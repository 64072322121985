import type { Instrument } from './market';
import type { Instant as Date } from '@js-joda/core';
import type { AccountDescriptor } from '@thinkalpha/platform-ws-client/contracts/account.js';

export const NewOrderSide = {
    BUY: 'buy',
    SELL: 'sell',
    CLOSEOUT: 'closeout',
} as const;
export type NewOrderSide = (typeof NewOrderSide)[keyof typeof NewOrderSide];

export const OrderSide = {
    BUY: 'buy',
    SELL: 'sell',
    SELLSHORT: 'sell-short',
    SELLSPLIT: 'split',
    CLOSEOUT: 'closeout',
} as const;
export type OrderSide = (typeof OrderSide)[keyof typeof OrderSide];

export enum OrderStatus {
    pending = 'pending',
    created = 'created',
    submitted = 'submitted',
    rejected = 'rejected',
    accepted = 'accepted',
    filled = 'filled',
    partiallyFilled = 'partially-filled',
    cancelPending = 'cancel-pending',
    killPending = 'kill-pending',
    canceled = 'canceled',
    updated = 'updated',
    updatePending = 'update-pending',
}

export enum OrderDuration {
    gtc = 'gtc',
    day = 'day',
}

export enum OrderType {
    MARKET = 'market',
    LIMIT = 'limit',
    STOP = 'stop',
    STOP_LIMIT = 'stop_limit',
    MARKET_ON_CLOSE = 'market_on_close',
    LIMIT_ON_CLOSE = 'limit_on_close',
    PEGGED = 'pegged',
    BOX_LIMIT = 'box_limit',
    BOX_MARKET = 'box_market',
}

export enum Exchange {
    ARCA = 'ARCA',
    BATS = 'BATS',
    BYXX = 'BYXX',
    EDGA = 'EDGA',
    EDGX = 'EDGX',
    NSDQ = 'NSDQ',
    NYSE = 'NYSE',
    PSX = 'PSX',
    BAML = 'BAML',
    BAIRD = 'BAIRD',
    CITI = 'CITI',
    CITADEL = 'CITADEL',
    EVERCORE = 'EVERCORE',
    JPM = 'JPM',
    NITE = 'NITE',
    RJET = 'RJET',
    SUSQ = 'SUSQ',
    TD = 'TD',
    TETHYS = 'TETHYS',
    UBS = 'UBS',
}

export enum Route {
    ROUT = 'ROUT',
    RDOT = 'RDOT',
    RDOX = 'RDOX',
    ROOC = 'ROOC',
    ROUX = 'ROUX',
    ROUZ = 'ROUZ',
    SWPA = 'SWPA',
    SWPB = 'SWPB',
    DIRC = 'DIRC',
    TRIM = 'TRIM',
    TRIM_MINUS = 'TRIM_MINUS',
    TRIM2 = 'TRIM2',
    TRIM2_MINUS = 'TRIM2_MINUS',
    TRIM3 = 'TRIM3',
    TRIM3_MINUS = 'TRIM3_MINUS',
    SLIM = 'SLIM',
    SLIM_PLUS = 'SLIM_PLUS',
    IOCM = 'IOCM',
    ICMT = 'ICMT',
    ROUC = 'ROUC',
    ROUD = 'ROUD',
    ROUE = 'ROUE',
    ROUQ = 'ROUQ',
    RMPT = 'RMPT',
    ROBB = 'ROBB',
    ROCO = 'ROCO',
    DOTA = 'DOTA',
    DOTD = 'DOTD',
    DOTI = 'DOTI',
    DOTM = 'DOTM',
    DOTZ = 'DOTZ',
    MOPP = 'MOPP',
    MOPB = 'MOPB',
    TFTY = 'TFTY',
    STGY = 'STGY',
    SCAN = 'SCAN',
    SKIP = 'SKIP',
    SKNY = 'SKNY',
    SAVE = 'SAVE',
    LIST = 'LIST',
    SOLV = 'SOLV',
    CART = 'CART',
    QSAV = 'QSAV',
    QFTY = 'QFTY',
    QSLV = 'QSLV',
    QCST = 'QCST',
    QDRK = 'QDRK',
    SMA = 'SMA',
    GETDONE = 'GETDONE',
    INSTINCT = 'INSTINCT',
    VWAP = 'VWAP',
    TWAP = 'TWAP',
    IS = 'IS',
    QMOC = 'QMOC',
    CAPTURE = 'CAPTURE',
    BLOCKSEEKER = 'BLOCKSEEKER',
    POV = 'POV',
    SOR = 'SOR',
    ARRIVAL = 'ARRIVAL',
    DARK = 'DARK',
    AUCTION = 'AUCTION',
    DAGGER = 'DAGGER',
    PARTICIPATE = 'PARTICIPATE',
    SCALING = 'SCALING',
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
    CITIBLOC = 'CITIBLOC',
    SMART_PEG = 'SMART_PEG',
    SMART_SOR = 'SMART_SOR',
    SMART_DARK = 'SMART_DARK',
    DESK_ROUTE = 'DESK_ROUTE',
    SMART_MOC = 'SMART_MOC',
    STRA_CONNECT = 'STRA_CONNECT',
    AQUA = 'AQUA',
    JPMCROSS = 'JPMCROSS',
    LADDER = 'LADDER',
    FAN = 'FAN',
    OASIS = 'OASIS',
    COVERT = 'COVERT',
    CATCH = 'CATCH',
    OPPORTUNISTIC = 'OPPORTUNISTIC',
    TARGETCLOSE = 'TARGETCLOSE',
    SUPSERSONIC = 'SUPERSONIC',
    LIFTOFF = 'LIFTOFF',
    HEATSEEKER = 'HEATSEEKER',
    RADAR = 'RADAR',
    STEALTH = 'STEALTH',
    AFTERBURNER = 'AFTERBURNER',
    DARKAGG = 'DARKAGG',
    EMINI = 'EMINI',
    EISFL = 'EISFL',
    ESTLT = 'ESTLT',
    TAP = 'TAP',
    TAPDARK = 'TAPDARK',
    TAPNOW = 'TAPNOW',
    CUSTOM = 'CUSTOM',
    CUSTOM1 = 'CUSTOM1',
    NONE = 'NONE',
    DMA = 'DMA',
    PEG = 'PEG',
    QTFY = 'QTFY',
    LANDING = 'LANDING',
    VWAPTWAP = 'VWAPTWAP',
    dma = 'dma',
    vwaptwap = 'vwaptwap',
    heater = 'heater',
    twilight = 'twilight',
    slider = 'slider',
    dark = 'dark',
    is = 'is',
    pov = 'pov',
    starter = 'starter',
    iex = 'iex',
    leadoff = 'leadoff',
    SMARTSEEKANDPOST = 'SMARTSEEKANDPOST',
    DARKPOST = 'DARKPOST',
}

export interface OrderBase {
    /**
     * The account uuid.
     */
    account?: string;
    side?: OrderSide;
    instrument: Instrument;
    /** For orders such as a limit order, the price at which to execute. Should be omitted for a market order */
    target?: number | null;
    duration?: OrderDuration;
    quantity?: number | null;
    type?: OrderType;
    exchange?: Exchange;
    route?: Route;

    /**
     * Each exchange accepts several strategies, and each of those is configured by a number of parameters,
     * some mandatory, but usually optional. This interface represents the entire set of possible parameters.
     * The relationship between the parameters is defined in the frontend only, in exchange_forms/
     * */
    attributes?: OptionalOrderInformation;
}

export interface ExecutorInfoBase {
    platform: string | null;
    account: string;
}

export interface NewExecutorInfo extends ExecutorInfoBase {}

export interface ExecutorInfo extends ExecutorInfoBase {
    senderCompId: string;
    senderSubId?: string;
    executorCompId: string;
    executorSubId?: string;

    /**
     * For orders originating from this trading service, the clOrdId will start
     * as the stringified version of the order id UUID.
     */
    clOrdId: string;
    prevClOrdIds: string[];
    ordIds: string[];
}

export interface OrderSummary extends OrderBase {
    id: string;
    creatorId?: string;
    status: OrderStatus;
    createdAt: Date;
    lastError?: string;
}

export interface Order extends OrderSummary {
    updates: OrderUpdate[];
}

export enum OrderUpdateType {
    cancelRejection = 'cancel-rejection',
    cancellation = 'cancel',
    correction = 'correct',
    status = 'status',
}

export interface OrderUpdateBase {
    type: OrderUpdateType;
    timestamp: Date;
}

export interface CancelRejectionUpdate extends OrderUpdateBase {
    type: OrderUpdateType.cancelRejection;
    text: string;
    reason: CancelRejectionReason;
    // requestId: string;
}

export interface ContraBrokerInfo {
    broker: string;
    quantity: number;
}

export interface OrderExecutionUpdate extends OrderUpdateBase {
    type: OrderUpdateType.cancellation | OrderUpdateType.correction | OrderUpdateType.status;
    quantity: number;
    quantityRemaining: number;
    price: number;
    text?: string;
    side: ExecutionSide;
    contraBrokers: ContraBrokerInfo[];
}

export type OrderUpdate = CancelRejectionUpdate | OrderExecutionUpdate;

export type OrderUpdateReport<T extends OrderUpdate = OrderUpdate> = T & {
    executorInfo: ExecutorInfo;
    orderId?: string;
    accountId?: string;
    status: OrderStatus;
    instrument: Instrument;
};

export enum CancelRejectionReason {
    TOO_LATE_TO_CANCEL = 'too-late-to-cancel',
    // UNKNOWN_ORDER = 'unknown-order',
    BROKER_OPTION = 'broker-option',
    ORDER_ALREADY_IN_PENDING_CANCEL_OR_PENDING_REPLACE_STATUS = 'already-pending-cancel',
    // new custom values
    UNMATCHED_ACCOUNT_SYMBOL = 'unknown-account-or-symbol',
}

export interface NewOrder extends Omit<OrderBase, 'account'> {
    // creatorId?: string;
    statusWebhook?: string;
    initialSide: NewOrderSide;
    account: string | AccountDesc;
}

export interface AccountDesc {
    name: string;
    platform: null;
}

export interface BlackboxOrder {
    kind: 'blackbox';
    clOrdId: string;
    account: string | AccountDesc;
    side: OrderSide;
    instrument: Instrument;
}

export interface NewExecutionBase extends OrderExecutionUpdate {
    instrument: Instrument;
}

export interface NewBlackboxExecution extends NewExecutionBase {
    kind: 'blackbox';
    account: string | AccountDesc;
    clOrdId?: string;
}

export interface NewDomesticExecution extends NewExecutionBase {
    kind: 'domestic';
    accountId: string;
    orderId?: string;
}

export type NewExecution = NewBlackboxExecution | NewDomesticExecution;

export interface CancellationRequest {
    orders: (string | BlackboxOrder)[];
    quantity: number | null;
}

export interface KillRequest {
    orders: (string | BlackboxOrder)[];
}

export interface CloseoutRequest {
    accounts: AccountDescriptor[];
    instruments: Instrument[];
    duration: OrderDuration;
}

export interface MassCancellationRequest {
    accounts: AccountDescriptor[];
    // ownerId?: string;
    instrument?: Instrument;
    side?: OrderSide;
    closeout: boolean;
}

export enum TradingStyle {
    MIN = 'MIN',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    MAX = 'MAX',
    B = 'B',
    ZERO = 'ZERO',
    ONE = 'ONE',
    TWO = 'TWO',
    THREE = 'THREE',
    FOUR = 'FOUR',
    FIVE = 'FIVE',
    SIX = 'SIX',
    BLOCKS = 'BLOCKS',
    DARK = 'DARK',
    GETDONE = 'GETDONE',
    PASSIVE = 'PASSIVE',
    MODERATE = 'MODERATE',
    AGGRESSIVE = 'AGGRESSIVE',
    M = 'M',
    P = 'P',
    R = 'R',
    PATIENT = 'PATIENT',
    NEUTRAL = 'NEUTRAL',
    FAST = 'FAST',
    PREOPEN = 'PREOPEN',
    ALL = 'ALL',
    REGULAR = 'REGULAR',
    TS_AGGRESSIVE = 'TS_AGGRESSIVE',
    TS_PATIENT = 'TS_PATIENT',
    TS_NORMAL = 'TS_NORMAL',
    VWAP = 'VWAP',
    TWAP = 'TWAP',
    POV = 'POV',
    RADAR = 'RADAR',
    STEALTH = 'STEALTH',
    SUPERSONIC = 'SUPERSONIC',
}

export enum SessionTarget {
    NONE = 'NONE',
    MAIN = 'MAIN',
    PRE = 'PRE',
    POST = 'POST',
}

export enum Instruction {
    NONE = 'NONE',
    PRICE_ADJUST = 'PRICE_ADJUST',
    MULTIPLE_PRICE_ADJUST = 'MULTIPLE_PRICE_ADJUST',
    CANCEL_BACK = 'CANCEL_BACK',
    HIDDEN = 'HIDDEN',
    DISPLAY_PRICE_SLIDING = 'DISPLAY_PRICE_SLIDING',
    DISPLAY_PRICE_SLIDING_NO_CROSSES = 'DISPLAY_PRICE_SLIDING_NO_CROSSES',
    MULTIPLE_DISPLAY_PRICE_SLIDING = 'MULTIPLE_DISPLAY_PRICE_SLIDING',
    VISIBLE = 'VISIBLE',
    INVISIBLE = 'INVISIBLE',
    NO_RESCRAPE_AT_LIMIT = 'NO_RESCRAPE_AT_LIMIT',
    HIDE_NOT_SLIDE = 'HIDE_NOT_SLIDE',
    ANON_PRICE_TO_COMPLY = 'ANON_PRICE_TO_COMPLY',
    ATTR_PRICE_TO_DISPLAY = 'ATTR_PRICE_TO_DISPLAY',
    NON_DISPLAY = 'NON_DISPLAY',
    IMBALANCE_ONLY = 'IMBALANCE_ONLY',
    POST_ONLY = 'POST_ONLY',
    MID_POINT_PEG = 'MID_POINT_PEG',
    MID_POINT_PEG_POST_ONLY = 'MID_POINT_PEG_POST_ONLY',
    ATTR_PRICE_TO_DISPLAY_POST_ONLY = 'ATTR_PRICE_TO_DISPLAY_POST_ONLY',
    RETAIL_ORDER_TYPE_1 = 'RETAIL_ORDER_TYPE_1',
    RETAIL_ORDER_TYPE_2 = 'RETAIL_ORDER_TYPE_2',
    RETAIL_PRICE_IMPROVEMENT_ORDER = 'RETAIL_PRICE_IMPROVEMENT_ORDER',
}

export enum AdaptiveRiskAversion {
    PIM = 'PIM',
    NEUTRAL = 'NEUTRAL',
    AIM = 'AIM',
}
export enum UpperPOV {
    MID = 'MID',
    BID = 'BID',
    OFFER = 'OFFER',
    NEAR = 'NEAR',
    FAR = 'FAR',
}

export enum TradingMode {
    ZERO = 'ZERO',
    ONE = 'ONE',
    TWO = 'TWO',
    THREE = 'THREE',
    FOUR = 'FOUR',
    FIVE = 'FIVE',
    SIX = 'SIX',
    R = 'R',
    M = 'M',
    P = 'P',
    SWEEPD = 'SWEEP DISPLAY',
    SWEEPND = 'SWEEP NO DISPLAY',
    DEEPSW = 'DEEP SWEEP',
    SWEEPIOC = 'SWEEP IOC',
    AUCTION = 'AUCTION',
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
    ARRIVAL = 'ARRIVAL',
    PASSIVE = 'PASSIVE',
    MIDPOINT = 'MIDPOINT',
    PCTOPEN = '% OF OPEN',
    PCTORDER = '% OF ORDER',
    SHARES = 'SHARES',
    POST = 'POST',
    STEALTH = 'STEALTH',
    CUSTOM = 'CUSTOM',
}

export interface OptionalOrderInformation {
    // flags
    adaptiveDark?: boolean;
    adaptiveMode?: boolean;
    adaptPriceToAIM?: boolean;
    adaptPriceToPIM?: boolean;
    afterburnerMode?: boolean;
    allowPrePostMkt?: boolean;
    anonymous?: boolean;
    auctionOnly?: boolean;
    buyBack?: boolean;
    closeParticipation?: boolean;
    completionRequired?: boolean;
    conditionals?: boolean;
    consolidateFills?: boolean;
    countDarkFills?: boolean;
    darkComplete?: boolean;
    darkOnly?: boolean;
    darkVenue?: boolean;
    disableDQuote?: boolean;
    finishToday?: boolean;
    includeBlockTrades?: boolean;
    includeClose?: boolean;
    includeOpen?: boolean;
    includeOrderVolume?: boolean;
    interlisted?: boolean;
    iOCOnly?: boolean;
    isTWAP?: boolean;
    litOnly?: boolean;
    litVenue?: boolean;
    momentumMode?: boolean;
    mustComplete?: boolean;
    noPostMode?: boolean;
    onClose?: boolean;
    onlyTradeAtClose?: boolean;
    onOpen?: boolean;
    openParticipation?: boolean;
    opportunistic?: boolean;
    passiveMode?: boolean;
    postClose?: boolean;
    postOutLoud?: boolean;
    preOpen?: boolean;
    showHiTouch?: boolean;
    sweepOnly?: boolean;
    tradeAtClose?: boolean;
    tradeAtOpen?: boolean;
    useMomentum?: boolean;
    finishInDark?: boolean;
    rule10B18?: boolean;
    maxLiqRebate?: boolean;
    onRule10b_18?: boolean;
    mLXNOnly?: boolean;
    momentumScaling?: boolean;
    reversionScaling?: boolean;
    allowPost?: boolean;
    onRegular?: boolean;
    tracking?: boolean;
    passiveLiquidity?: boolean;
    closingOffset?: boolean;
    parityReservation?: boolean;
    parityEquote?: boolean;
    targetLocationId?: boolean;
    MPEG?: boolean;
    rule10b_18?: boolean;
    frontWeight?: boolean;
    IEXVWAP?: boolean;
    maxPOVToDark?: boolean;
    shareWithHTTrader?: boolean;
    noPost?: boolean;
    nitroOption?: boolean;
    disableConditionals?: boolean;

    // dates
    startTime?: Date;
    endTime?: Date;
    // numbers
    duration?: number;
    maxPctVolume?: number;
    minPctVolume?: number;
    sliceValue?: number;
    targetPrice?: number;
    targetValue?: number;
    upperTargetValue?: number;
    lowerTargetValue?: number;
    secondaryPrice?: number;
    auxiliaryPrice?: number;
    displaySize?: number;
    wouldPrice?: number;
    wouldDarkPct?: number;
    wouldDarkPrice?: number;
    minDarkFillSize?: number;
    maxFloor?: number;
    minQty?: number;
    finishInDarkPrice?: number;
    stopPrice?: number;
    closeAuctionQty?: number;
    closeAuctionPctOfSize?: number;
    closeAuctionPctOfVolume?: number;
    targetPOV?: number;
    participateOnOpenPct?: number;
    wouldIfMinFillQty?: number;
    wouldIfPrice?: number;
    targetParticipationRate?: number;
    speedTarget?: number;
    getDonePrice?: number;
    speedPrice?: number;
    minCrossQty?: number;

    // enums
    tradingStyle?: TradingStyle;
    tradingMode?: TradingMode;
    sessionTarget?: SessionTarget;
    instruction?: Instruction;
    adaptiveRiskAversion?: AdaptiveRiskAversion;
    upperPOV?: UpperPOV;

    // Custom instructions
    customInstr?: string;
}

export enum ExecutionSide {
    Buy = 'buy',
    Sell = 'sell',
    // BUY_MINUS = '3',
    // SELL_PLUS = '4',
    SellShort = 'sell-short',
    // SELL_SHORT_EXEMPT = '6',
    // D = '7',
    // CROSS = '8',
    // CROSS_SHORT = '9',
    // COMPLEX = 'B',
    SellSplit = 'sell-split',
    Closeout = 'closeout',
}
